<template>
    <b-modal
        id="answerTestModal"
        size="lg"
        centered
        title="Teste de Respostas"
        footer-bg-variant="light"
        no-close-on-backdrop
        @hide="reset"
    >
        <div class="chat-container" @change="scrollToEnd">
            <chat-container class="h-100" v-if="messages.length > 0">
                <chat-message
                    v-for="(message, i) in messages"
                    :key="`answer-test-message-${i}`"
                    :show-brands="false"
                    :align="message.me == true ? 'right' : 'left'"
                    :data="message"
                />
            </chat-container>
            <div style="height: 70vh;" class="text-center d-flex flex-column align-items-center justify-content-center"
                 v-else>
                <div>
                    <font-awesome-icon icon="fa-solid fa-robot" size="lg"/>
                    <h2 class="mt-2">Simulação de respostas</h2>
                    <p>Comece digitando uma mensagem para simular as respostas automáticas.</p>
                </div>
            </div>
        </div>
        <template #modal-footer class="p-0">
            <div class="d-flex w-100 bg-light align-items-center">
                <emoji-picker class="mx-2" @emoji="append"></emoji-picker>

                <input
                    @keyup.enter="sendMessage"
                    v-model="message"
                    type="text"
                    placeholder="Sua mensagem"
                    class="regular-input mr-1"
                >

                <button @click="sendMessage" class="btn btn-primary ml-2 d-flex align-items-center">
                    Enviar
                    <font-awesome-icon icon="fa-solid fa-paper-plane" class="text-white ml-1"/>
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import {BModal} from "bootstrap-vue";
import ChatContainer from "@/views/components/ChatLog/ChatContainer.vue";
import ChatMessage from '@/views/components/ChatLog/ChatMessage.vue';
import stringSimilarity from 'string-similarity'
import EmojiPicker from '@/views/components/Emoji.vue';

const acceptedSimilarity = 0.75;

export default {
    components: {
        BModal,
        ChatContainer,
        ChatMessage,
        EmojiPicker,
    },
    props: {
        id: {
            type: String,
            default: () => "answerTestModal"
        },
        value: {
            type: Object,
            default: () => null
        }
    },
    data() {
        return {
            messages: [],
            message: '',
            input: '',
        }
    },
    methods: {
        reset() {
            this.messages = [];
            this.message = '';
            this.input = null
            this.search = null
        },
        sendMessage() {
            if (!this.message) return;
            this.messages.push({
                name: this.$store.state.user.user.name,
                message: this.message,
                me: true,
                start_tree: false,
                created_at: new Date()
            });
            this.message = '';

            setTimeout(() => this.scrollToEnd(), 300);
        },
        scrollToEnd() {
            const el = document.querySelector('.chat-container');
            el.scrollTop = el.scrollHeight + 1000;
            this.answerGenerator();
        },
        /**
         * Simula o comportamento da resposta automática no backend.
         */
        answerGenerator() {
            const vm = this;

            /**
             * Normaliza a string, removendo quaisquer caracteres extras como: emojis, acentos e pontuações.
             * @param {string} text
             * @return {string}
             */
            function normalize(text) {
                return String(text)
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLocaleLowerCase()
                    .replaceAll(/[^0-9a-z]/g, "");
            }

            /**
             * Verifica se as strings são semelhantes.
             * @param {string} a
             * @param {string} b 
             * @return {boolean}
             */
            function isSimilar(a, b) {
                let normalizedA = normalize(a);
                let normalizedB = normalize(b);

                const percentual = stringSimilarity.compareTwoStrings(normalizedA, normalizedB);
                const result = percentual > (acceptedSimilarity * 100);

                if(!result) return normalizedB.includes(normalizedA);

                return true;
            }

            /**
             * Verifica se as strings são semelhantes com sinônimos.
             * @param {string} raw Texto base a ser comparado
             * @param {object} text Texto com sinônimos
             * @param {string} type Tipo de comparação
             * @return {boolean}
             */
            function isSimilarWithSynonyms(raw, text, type = 'always') {
                const normalized = normalize(raw);

                if(text.title == '*')
                    return true;
                if(text.title == '\\w')
                    return normalized.match(/\w/);
                if(text.title == '\\d')
                    return normalized.match(/\d/);

                const array = getAllVariationsBySynonyms(text.title, text.synonyms ?? []);

                let result = false;
                array.every(variation => {
                    if(type == 'exactly')
                        result = normalize(variation) == normalized;
                    else
                        result = isSimilar(variation, raw);

                    return !result;
                });

                return result;
            }

            /**
             * Envia a mensagem da resposta.
             * @param {object} answer
             * @param {boolean} start_tree
             * @return {boolean}
             */
            function sendAnswerMessage(answer, start_tree = false) {
                let variations = getAllVariationsBySynonyms(answer.text, answer.synonyms);
                const message = variations[Math.floor(Math.random() * variations.length)];
                vm.messages.push({
                    name: "Resposta",
                    message,
                    me: false,
                    start_tree,
                    created_at: new Date()
                });
                setTimeout(() => vm.scrollToEnd(), 300);
            }

            /**
             * Verifica o histórico de mensagens para saber se a resposta está na sequência correta.
             * @param {object} answer
             * @return {boolean}
             */
            function isSequentialAnswer(answer) {
                const messages = Array.from([...vm.messages]).reverse();
                const lastStartedTreeIndex = messages.findIndex(e => e.start_tree && e.start_tree == true);
                let lastMessages = messages.slice(0, lastStartedTreeIndex >= 0 ? lastStartedTreeIndex + 2 : -1).filter(e => e.me);

                if(answer.parents.length != lastMessages.length - 1)
                    return false;

                let result = true;
                Array.from(answer.parents).reverse().every((obj, index) => {
                    const msg = lastMessages[index + 1];
                    let has = false;

                    Array.from(obj.neddles).every(neddle => {
                        if(isSimilarWithSynonyms(msg.message, neddle, answer.frequency))
                            has = true;
                        return !has;
                    });

                    if(!has) result = false;
                    return result;
                });


                return result;
            }

            /**
             * Insere os sinônimos em um texto e retorna todas as variações.
             * @param {string} text Texto base
             * @param {object[]} synonyms Sinônimoos com sentenças
             * @return {string[]}
             */
            function getAllVariationsBySynonyms(text, synonyms) {
                let array = [text];
                
                if(synonyms != null && synonyms != []) {
                    synonyms.forEach(synonym => {
                        const neddle = synonym.sentence;
                        const synms = [...synonym.synonyms, neddle];
                        const regex = new RegExp(neddle, 'ig');

                        const matches = [...text.matchAll(regex)];
                        if(matches.length > 0) {
                            matches.forEach(() => {
                                array.forEach(text => {
                                    synms.map(synm => {
                                        array.push(text.replace(regex, synm));
                                    })
                                })
                            })
                            array = [...new Set(array)];
                        }
                    })
                }

                return [...array.values()];
            }

            /**
             * Transforma a resposta recursiva em um array de respostas unidimensional.
             * @param {object} answer
             * @return {object[]}
             */
            function flatRecursiveAnswers(answer) {
                let array = [];

                /**
                 * Recursivamente vai transformando a resposta base em um array.
                 * @param {object} answer
                 * @param {object} root
                 * @param {object[]} parent
                 */
                function recursiveAddAnswer(answer, root, parent = null) {
                    const item = {
                        campaign_id: root.campaign_interaction_id ?? null,
                        name: root.name ?? null,
                        frequency: root.frequency,
                        neddles: answer.neddles,
                        answers: answer.answers,
                        parents: parent ?? []
                    }

                    array.push(item);

                    answer.answers.forEach(obj => {
                        if(obj.neddles != null && obj.neddles.length > 0) {
                            obj.neddles.forEach(neddle => {
                                recursiveAddAnswer(neddle, root, parent == null ? [item] : [...parent, item]);
                            })
                        }
                    })
                }

                recursiveAddAnswer(answer, answer);

                return array.sort((a, b) => b.parents.length - a.parents.length);
            }
            
            /**
             * Faz uma verificação nos neddles das respostas e retornas os models que satisfazem o texto
             * passado, usando um cálculo de similaridade.
             * @param {string} neddle String a ser comparada para obter as respostas referentes
             * @return {object[]}
             */
            function getCompatibleAnswers(neddle) {
                const answers = flatRecursiveAnswers(vm.$props.value);
                const compatibleAnswers = answers.filter(answer => {
                    for (let i = 0; i < answer.neddles.length; i++) {
                        const obj = answer.neddles[i];

                        if(isSimilarWithSynonyms(neddle, obj, answer.frequency)) {
                            /**
                             * Verifica se é uma resposta sequencial.
                             */
                            if(answer.parents != null && answer.parents.length > 0) {
                                return isSequentialAnswer(answer);
                            }
                            return true;
                        }
                    }
                });

                return compatibleAnswers;
            };

            if (vm.messages.length == 0 || Array.from(vm.messages).pop().me != true) return;

            const messages = Array.from([...vm.messages]).filter(e => e.me);
            if (messages.length == 0) return;

            const lastMessage = messages.pop().message;
            const compatibleAnswers = getCompatibleAnswers(lastMessage);

            if(compatibleAnswers.length == 0) return;

            const answer = compatibleAnswers[0];
            
            if(answer.answers != null && answer.answers.length > 0 && answer.answers[0].text != null) {
                const response = answer.answers[0];
                
                if(answer.frequency != 'suggest')
                    sendAnswerMessage(response, answer.parents.length == 0);
            }

        },
        append(emoji) {
            this.message += emoji;
        },
    },
}
</script>

<style lang="scss" scoped>
.chat-container {
    min-height: 70vh;
    max-height: 70vh;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #949494 #DDD;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #DDD;
        border-radius: 10px;
        border: 0 solid transparent;
    }
}

.regular-input {
    padding: 0.5rem 1rem;
    border-radius: 3px;
    border: 1px solid #ccc;
    width: 100%;
    height: 2.5rem;
    outline: none;
    margin-top: 0.1rem;
}

#regular-input:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, .5);
}

#fileInput {
    display: none;
}

h1,
h2 {
    font-weight: normal;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

.my-8 {
    margin-top: 4rem;
    margin-bottom: 4rem;
}
</style>
