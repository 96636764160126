<template>
    <div :class="{left: align == 'left'}" class="chat-line px-2 py-2">
        <div class="chat-content">
            <p v-if="showName" class="text-left text-blue font-weight-normal">
                <font-awesome-icon v-if="showBrands" icon="fa-brands fa-whatsapp"/>
                {{ data.name }}
            </p>
            <p class="font-weight-normal text-wrap text-break" v-html="data.message"></p>
        </div>
        <small class="chat-time mb-0">{{ stringDate }}</small>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    props: {
        align: {
            type: String,
            default: () => 'left'
        },
        showBrands: {
            type: Boolean,
            default: () => true
        },
        showName: {
            type: Boolean,
            default: () => true
        },
        data: {
            type: Object,
            default: () => {
                return {
                    name: 'Joaquim',
                    social_network: 'whatsapp',
                    message: 'Um ninho de mafagafos tem 7 mafagafinhos.',
                    created_at: '2022-06-03T12:29:36.940+00:00'
                }
            }
        }
    },
    computed: {
        stringDate() {
            if (!this.$props.data) return 'Agora';

            let date = moment(this.$props.data.created_at);
            let now = moment();

            const time = date.format('HH:mm');

            if (date.isSame(now, 'day')) return `Hoje ${time}`;
            if (date.isSame(now.subtract(1, 'day'))) return `Ontem ${time}`;
            if (date.isSame(now, 'year')) return date.format('DD MMM HH:mm');
            return date.format('DD MMM YYYY HH:mm')
        }
    }
}
</script>
