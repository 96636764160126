<template>
    <b-modal
        id="saveSubjectModal"
        centered
        title="Salvar assunto"
        header-border-variant="light"
        footer-border-variant="light"
        content-class="p-4 position-relative"
        body-class="position-unset"
        :no-close-on-backdrop="loading"
        :no-close-on-esc="loading"
        lazy
        @show="reset"
    >
        <loading v-if="loading" />

        <validation-observer ref="addSubjectForm">
            <validation-provider
                #default="{ failed }"
                rules="required"
                class="w-100"
            >
                <input
                    v-model="subject.name"
                    type="text"
                    class="form-control m-0"
                    :class="{ 'is-invalid': failed }"
                    placeholder="Nome"
                >
            </validation-provider>
        </validation-observer>

        <template #modal-footer="{ cancel, ok }">
            <button @click="cancel" class="btn btn-outline-primary px-5 mr-2">
                Cancelar
            </button>
            <button @click="submit(ok)" class="btn btn-primary px-5">
                Salvar
            </button>
        </template>
    </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BModal } from "bootstrap-vue";
import Loading from "@/views/components/Loading.vue";
import { required } from "@validations";
import api from "@/api";

const subjectModel = {
    name: null,
};

export default {
    components: {
        BModal,
        ValidationProvider,
        ValidationObserver,
        Loading
    },
    data() {
        return {
            required,
            subject: Object.assign({}, this.$props.value != null ? this.$props.value : subjectModel),
            loading: false
        };
    },
    props: ['value'],
    emits: ['input'],
    methods: {
        reset() {
            Object.assign(this.subject, this.$props.value != null ? this.$props.value : subjectModel)
        },
        submit(closeHandler) {
            this.$refs.addSubjectForm.validate().then(success => {
                if(!success) return;

                this.loading = true;
                const id = this.$props.value != null ? this.$props.value._id : null;
                api({
                    method: id ? 'PUT' : 'POST',
                    url: id ? `/answers/subjects/${id}` : "/answers/subjects",
                    data: this.subject
                })
                .then(response => {
                    const { type, message, body } = response.data;

                    if(type == 'success') {
                        this.$bvToast.toast(message, {title: 'Assuntos', variant: 'success'});
                        this.$emit('input', body);
                        return closeHandler()
                    }

                    throw message;
                })
                .catch(error => {
                    let message = 'Não foi possível salvar assunto.'
                    if(error.response) {
                        message = error.response.data.message;
                    }
                    if(typeof error == 'string') {
                        message = error
                    }
                    console.log(`saveSubject: ${error}`);
                    this.$bvToast.toast(message, {title: 'Assuntos', variant: 'danger'});
                })
                .finally(() => this.loading = false);
            })
        },
    },
}
</script>

<style>
.position-unset {
    position: unset !important;
}
</style>
